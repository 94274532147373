import type {Source, VideoComponentWithDifferentQualities} from './types';

export function getSource({
  video,
  isSafari,
  quality,
}: {
  video: VideoComponentWithDifferentQualities;
  isSafari: boolean | null;
  quality: string | null;
}): Source {
  const source: Source = {};

  if (isSafari === null) return source;

  // If no qualities are provided, fallback to video.webmSrc || video.mp4Src
  if (video.webmSrc && !isSafari) {
    source.src = video.webmSrc;
    source.codec = 'video/webm';
  }

  if (video.mp4Src && isSafari) {
    source.src = video.mp4Src;
    source.codec = 'video/mp4';
  }

  if (video.qualities && quality && video.qualities[quality]) {
    if (video.qualities[quality].webmSrc && !isSafari) {
      source.src = video.qualities[quality].webmSrc;
      source.codec = 'video/webm';
    }
    if (video.qualities[quality].mp4Src && isSafari) {
      source.src = video.qualities[quality].mp4Src;
      source.codec = 'video/mp4';
    }
  }

  return source;
}

export function getAppropriateQuality({
  video,
}: {
  video: VideoComponentWithDifferentQualities;
}) {
  if (!video.qualities) return null;
  const qualities = Object.keys(video.qualities);

  const qualitiesNumbers = qualities
    .map((q) => parseInt(q.replace('p', ''), 10))
    .sort();

  // monitor network connection speed and device capabilities
  let activeQuality = Math.max(...qualitiesNumbers);

  //@ts-ignore
  const networkInfo = navigator && navigator.connection;
  const mediaCapabilities = navigator && navigator.mediaCapabilities;

  if (networkInfo && mediaCapabilities) {
    // Downgrade quality if network connection speed is slow or device is not capable of decoding video
    if (
      networkInfo.downlink < 10 ||
      //@ts-ignore
      !mediaCapabilities.decodingInfo?.powerEfficient
    ) {
      if (qualitiesNumbers[qualitiesNumbers.length - 1])
        activeQuality = qualitiesNumbers[qualitiesNumbers.length - 1];
    }

    // Downgrade quality if network connection speed is slow or device is not capable of decoding video
    if (
      networkInfo.downlink < 5 ||
      //@ts-ignore
      !mediaCapabilities.decodingInfo?.supported
    ) {
      if (qualitiesNumbers[qualitiesNumbers.length - 2])
        activeQuality = qualitiesNumbers[qualitiesNumbers.length - 2];
    }
  }

  return `${activeQuality}p`;
}

export function getVideoPlaying({video}: {video: HTMLVideoElement}) {
  return !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  );
}
