import {useRef, useCallback, useState, useEffect} from 'react';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import Image from '@/components/shared/Image/Image';
import PlayPauseSVG from '@/components/shared/WistiaVideoPlayer/components/PlayPauseSvg';

import {getSource, getAppropriateQuality, getVideoPlaying} from './utils';
import type {VideoComponentWithDifferentQualities} from './types';

export interface InlineVideoProps
  extends Omit<
    React.HtmlHTMLAttributes<HTMLDivElement>,
    'children' | 'onEnded' | 'onTimeUpdate'
  > {
  video: VideoComponentWithDifferentQualities;
  playButtonClassName?: string;
  reduceMotion?: boolean;
  isInView?: boolean;
  showPlayButton?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  usePoster?: boolean;
  preload?: string;
  onEnded?: (videoElement: HTMLVideoElement) => void;
  onTimeUpdate?: (videoElement: HTMLVideoElement) => void;
}

// TODO: Refactor this to simplify it
// eslint-disable-next-line complexity
export default function InlineVideo({
  video,
  reduceMotion,
  isInView = true,
  playButtonClassName,
  autoPlay = true,
  loop = true,
  muted = true,
  showPlayButton = true,
  usePoster = false,
  preload = 'auto',
  onEnded,
  onTimeUpdate,
  className,
  ...props
}: InlineVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isSafari, setIsSafari] = useState<boolean | null>(null);
  const [quality, setQuality] = useState<string | null>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [canPlayVideo, setCanPlayVideo] = useState(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isHoveredOrFocused, setIsHoveredOrFocused] = useState(false);

  let isLoaded = isVideoLoaded;
  if (
    !isLoaded &&
    videoRef.current &&
    videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA
  )
    isLoaded = true;

  if (
    isInView &&
    isLoaded &&
    !isVideoPlaying &&
    videoRef.current &&
    canPlayVideo
  ) {
    videoRef.current.play();
  }

  if (!isInView && isVideoPlaying && isLoaded && videoRef.current) {
    videoRef.current.pause();
  }

  useEffect(() => {
    setCanPlayVideo(isInView);
  }, [isInView]);

  const prefersReducedMotion =
    useMediaQuery('(prefers-reduced-motion: reduce)') || reduceMotion;

  useEffect(() => {
    if (!videoRef.current || !isLoaded) return;
    const videoEl = videoRef.current;

    const handlePlay = () => {
      setIsVideoPlaying(true);
    };

    const handlePause = () => {
      setIsVideoPlaying(false);
    };

    videoEl.addEventListener('play', handlePlay);
    videoEl.addEventListener('pause', handlePause);

    return () => {
      videoEl.removeEventListener('play', handlePlay);
      videoEl.removeEventListener('pause', handlePause);
    };
  }, [videoRef, isLoaded]);

  // Using custom instead of the browser detection hook
  // By having the initial value set to null, we can ensure
  // we know the browser is or is not safari and then load the proper source
  useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, [setIsSafari]);

  useEffect(() => {
    const activeQuality = getAppropriateQuality({video});
    setQuality(activeQuality);
  }, [video]);

  const handleButtonClick = useCallback(() => {
    if (!videoRef.current || !isLoaded) return;
    const isPlaying = getVideoPlaying({video: videoRef.current});

    if (isPlaying) {
      setCanPlayVideo(false);
      videoRef.current.pause();
    } else {
      setCanPlayVideo(true);
      videoRef.current.play();
    }
  }, [videoRef, isLoaded]);

  const source = getSource({video, quality, isSafari});
  const ariaPlay = 'Play video';
  const ariaPause = 'Pause video';

  let posterImg = video.image && video.image.src ? video.image.src : undefined;
  if (!posterImg && video.image && video.image.srcSet)
    posterImg = video.image.srcSet;

  useEffect(() => {
    if (!onTimeUpdate || !videoRef.current) return;

    let raf = 0;

    const onRaf = () => {
      if (videoRef.current) onTimeUpdate(videoRef.current);
      raf = window.requestAnimationFrame(onRaf);
    };

    raf = window.requestAnimationFrame(onRaf);

    return () => {
      window.cancelAnimationFrame(raf);
    };
  }, [videoRef, onTimeUpdate]);

  return (
    <div className={twMerge('relative h-full', className)} {...props}>
      {source.src && !prefersReducedMotion && (
        <div
          onMouseEnter={() => setIsHoveredOrFocused(true)}
          onMouseLeave={() => setIsHoveredOrFocused(false)}
          onFocus={() => setIsHoveredOrFocused(true)}
          onBlur={() => setIsHoveredOrFocused(false)}
          className="video-container h-full"
          style={{
            aspectRatio: video.aspectRatio ? video.aspectRatio : undefined,
          }}
        >
          {showPlayButton && (
            <button
              aria-label={isVideoPlaying ? ariaPause : ariaPlay}
              aria-pressed={isVideoPlaying}
              data-component-name={`${isVideoPlaying ? 'pause' : 'play'}-video`}
              className={twMerge(
                'p-0 m-0 appearance overflow-hidden w-16 ',
                'h-16 absolute scale-100 z-10 transform transition-all',
                'left-1 bottom-1',
                `${isLoaded ? '' : 'hidden'}`,
                `${
                  isVideoPlaying && !isHoveredOrFocused
                    ? 'md:opacity-0'
                    : 'md:opacity-100'
                }`,
                playButtonClassName,
              )}
              onClick={handleButtonClick}
              tabIndex={0}
            >
              <PlayPauseSVG showPlayState={isVideoPlaying} />
            </button>
          )}
          <video
            key={source.src}
            ref={videoRef}
            className={cn(
              'opacity-0 transition-opacity ease-out-cubic duration-300 h-full',
              {
                'opacity-100': isLoaded || usePoster,
              },
            )}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            playsInline
            preload={preload}
            poster={usePoster ? posterImg : undefined}
            onCanPlay={() => setIsVideoLoaded(true)}
            onCanPlayThrough={() => setIsVideoLoaded(true)}
            onLoadedData={() => setIsVideoLoaded(true)}
            onEnded={() =>
              onEnded && videoRef.current && onEnded(videoRef.current)
            }
            onTimeUpdate={() =>
              onTimeUpdate && videoRef.current && onTimeUpdate(videoRef.current)
            }
          >
            <source src={source.src} type={source.codec} />
          </video>
        </div>
      )}
      {video.image && prefersReducedMotion && (
        <Image
          className="absolute w-full h-full left-0 top-0 object-contain"
          loading="lazy"
          {...video.image}
        />
      )}
    </div>
  );
}
